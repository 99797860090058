<template>
  <article
    class="BannerUserType ff-signa"
    :class="[{ 'is-dark': isDark }, classNames]"
  >
    <div class="BannerRow">
      {{ $t('BannerSelectRole.text') }}
    </div>
    <div class="BannerRow">
      <div class="BannerItem">
        <div class="ImageContainer">
          <img
            src="@/assets/images/avatar-recipient.png"
            alt="Recipient"
            svg-inline
          >
        </div>
        <div class= "UserTypeButton">
          <button
            type="button"
            class="Button form-button is-link d-block"
            v-text="$t('BannerSelectRole.roles.recipient')"
            @click="openUserTypeConfirmation('Recipient')"
          />
        </div>
      </div>
      <div class="BannerItem">
         <div class="ImageContainer">
            <img
                src="@/assets/images/avatar-caregiver.png"
                alt="Caregiver"
                svg-inline
              >
          </div>
        <div class= "UserTypeButton">
          <button
            type="button"
            class="Button form-button is-link d-block"
            v-text="$t('BannerSelectRole.roles.caregiver')"
            @click="openUserTypeConfirmation('Caregiver')"
          />
        </div>
      </div>
      <div class="BannerItem">
        <div class="ImageContainer">
          <img
            src="@/assets/images/avatar-professional-small.png"
            alt="Professional"
            svg-inline
          >
        </div>
        <div class= "UserTypeButton">
          <button
            type="button"
            class="Button form-button is-link d-block"
            v-text="$t('BannerSelectRole.roles.professional')"
            @click="openUserTypeConfirmation('Professional')"
          />
        </div>
      </div>
    </div>

    <sweet-modal
      hide-close-button
      blocking
      ref="userTypeConfirmation"
      icon="info"
      >

      <div id="confirmation" class="Content has-grey-bg">
        <p>{{ $t('BannerSelectRole.confirmationText') + $t(`BannerSelectRole.roles.${userType.toLowerCase()}`) }}</p>
        <div class="ButtonRow">
          <button
            class="Button form-button d-block ConfirmButton"
            v-text="$t('submitButtonLabel')"
            @click="handleRoleSelection"
          />
          <button
            class="Button form-button is-link d-block ConfirmButton"
            v-text="$t('cancelButtonLabel')"
            @click="cancelUserTypeSelection"
          />
        </div>
      </div>
    </sweet-modal>

    <sweet-modal
      hide-close-button
      blocking
      ref="backendError"
      icon="error"
      >

      <div class="Content has-grey-bg">
        <p>{{ $t('BannerSelectRole.backendError') }}</p>
        <div class="ButtonRow">
          <button
            class="Button form-button d-block ConfirmButton"
            v-text="$t('okButtonLabel')"
            @click="closeErrorModal"
          />
        </div>
      </div>
    </sweet-modal>
  </article>
</template>

<script>

import store from '@/store/index.js'

export default {
  name: 'BannerUserType',

  props: {
    isDark: {
      type: Boolean,
      default: false
    },

    classNames: {
      type: String,
      default: ''
    }
  },
  computed: {
    userTypes () {
      return ['recipient', 'caregiver', 'professional']
    }
  },
  data () {
    return {
      userType: ''
    }
  },

  methods: {
    cancelUserTypeSelection () {
      this.userType = ''
      this.$refs.userTypeConfirmation.close()
    },

    closeErrorModal () {
      this.$refs.backendError.close()
    },

    openUserTypeConfirmation (value) {
      this.userType = value
      this.$refs.userTypeConfirmation.open()
    },

    async handleRoleSelection () {
      this.$refs.userTypeConfirmation.close()

      if (this.$auth0.isAuthenticated) {
        try {
          await store.dispatch('user/updateUserProfile', { userType: this.userType })
        } catch (error) {
          this.$refs.backendError.open()
        }
      }

      // reset url, so services are loaded
      store.commit('user/setLastLoadedServiceUrl', '')
      store.commit('user/setLastLoadedInactiveServiceUrl', '')
      store.dispatch('user/getServiceClaims')
      store.dispatch('user/getInactiveServicesFromDB')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../styles/_component-imports';

.BannerUserType {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 1.5em 2em;
  padding-right: 1.5em;
  border: 1px solid $color-grey-300;
  background-color: $white;
  font-size: 30px;
  width: 100%;

  &.is-dark {
    background-color: $color-grey-700;
    color: $white;

    .logo .medel-logo {
      fill: $white;
    }
  }

  &.n-m-lr {
    @include media('>=md') {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  .Button {
    text-align: left;

    @include media('>=md') {
      text-align: center;
    }
  }
}
.BannerRow {
  display: flex;
  flex-wrap: wrap;
  align-items: bottom;

}
.BannerItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 80px;
  align-items: center;
}
.ImageContainer {
  display: flex;
  flex: 1 1 auto;
  padding: 10%;
}
img {
  max-width: 100%;
  align-self: center;
}
.ButtonRow {
  display: flex;
  justify-content:center;
  flex-wrap: wrap;
}
.UserTypeButton {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
}
.ConfirmButton {
  margin: 5px;
}
</style>
