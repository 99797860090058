// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@-webkit-keyframes MoveArrowUpDown{0%,to{-webkit-transform:translate3d(-50%,0,0);transform:translate3d(-50%,0,0)}40%{-webkit-transform:translate3d(-50%,20px,0);transform:translate3d(-50%,20px,0)}}@keyframes MoveArrowUpDown{0%,to{-webkit-transform:translate3d(-50%,0,0);transform:translate3d(-50%,0,0)}40%{-webkit-transform:translate3d(-50%,20px,0);transform:translate3d(-50%,20px,0)}}.sticky{position:fixed;bottom:20px;right:20px;width:500px;z-index:100}@media only screen and (max-width:500px){.sticky{max-width:100%;bottom:0;right:0}}.inactive{opacity:.4}.linkButton,.linkButton:hover{background:none;color:#979594}.row{margin-top:10px}.Dashboard{padding-top:180px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;padding-bottom:80px;min-height:calc(100vh - 153px);overflow:auto}@media(min-width:480px){.Dashboard{min-height:calc(100vh - 83px)}}@media(min-width:768px){.Dashboard{min-height:calc(100vh - 61px)}}.Dashboard .content{color:#646464;line-height:1.5;margin-top:0}.Dashboard .content.sub-headline{margin:10px 0 60px}@media(min-width:1024px){.Dashboard .content.sub-headline{margin-top:20px}}.Dashboard ul{list-style:none;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap}@media(min-width:768px){.Dashboard ul{margin-right:-20px}}.Dashboard ul li{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;margin-bottom:20px;width:100%}@media(min-width:768px){.Dashboard ul li{width:calc(50% - 20px);margin-right:20px}}@media(min-width:1280px){.Dashboard ul li{width:calc(33.33% - 20px);margin-right:20px}}", ""]);
// Exports
module.exports = exports;
