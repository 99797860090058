<template>
    <div v-if="!isHidden">
    <article
      class="BannerFeedback">
      <p class="closeButton" @click="closeBanner()">X</p>
      <p class="bold">{{$t('feedbackForm.title')}}</p>
      <p class="content">
        {{$t('feedbackForm.body')}}
      </p>
        <div>
          <button
            type="button"
            class="Button left form-button is-link"
            v-text="$t('feedbackForm.formButtonCancel')"
            @click="dontShowAgain()"
          />
          <button
            type="button"
            class="Button right form-button is-link"
            v-text="$t('feedbackForm.formButton')"
            @click="goToSurvey()"
          />
        </div>
    </article>
    </div>
</template>

<script>
import { appSettings } from '@/settings'
export default {
  name: 'BannerFeedback',
  data () {
    return {
      isHidden: false,
      language: this.$i18n.locale ? this.$i18n.locale : 'en'
    }
  },
    methods: {
        goToSurvey () {
            if (this.language === 'de') {
                window.open(appSettings.feedback?.urlDe, '_blank')
            } else {
                window.open(appSettings.feedback?.url, '_blank')
            }
        },
        dontShowAgain () {
            localStorage.setItem('showFeedbackBanner', false)
            this.isHidden = true
        },
        closeBanner () {
            this.isHidden = true
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../styles/_component-imports';

.BannerFeedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0.5em 2em;
  padding-right: 1.5em;
  padding-bottom: 1em;
  border: 1px solid $color-grey-300;
  background-color: $color-grey-100;
  font-size: 15px;

  .content {
    min-height: 100px;
  }

  @media only screen and (max-width: 500px){
    .content  {
      margin-bottom: 30px;
    }
  }

  .closeButton{
    position: absolute;
    right: 20px;
    top: 0em;
    font-size: 20px;
    cursor: pointer;
  }

  .bold{
    font-weight: bold;
  }

  .Button {
    display: inline-block;
    text-align: left;
    font-size: 15px;
  }
  .right {
    position: absolute;
    right: 2em;
    bottom: 20px;
  }

  .left {
    position: absolute;
    left: 2em;
    bottom: 20px;
  }
}
</style>
