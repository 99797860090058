<template>
  <a
    :href="url"
    :title="label"
    :class="['Button', customCss]"
    v-text="label"
    :target="openBlank ? '_blank' : '_self'"
    v-on:click="$event"
  />
</template>

<script>
export default {
  name: 'LinkButton',

  props: {
    label: {
      type: String,
      default: 'ButtonText'
    },
    url: {
      type: String,
      default: '#'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    openBlank: {
      type: Boolean,
      default: false
    },
    customCss: {
      type: String,
      default: ''
    }
  }
}
</script>
