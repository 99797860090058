<template>
  <section
    ref="dashboard"
    class="Dashboard"
  >

    <sweet-modal
      ref="termsOfUseModal"
      icon="info"
      overlay-theme="dark"
      hide-close-button
      blocking
    >
      <div class="termsOfUseContent">
        <h1 class="Headline is-size-4" v-t="'termsOfUse.confirmTermsOfUseTitle'" />
        <div class="content sub-headline">
          <i18n path="termsOfUse.termsOfUseMessages[0]" tag="p">
            <template #termsOfUseLink>
              <a :href="termsOfUseUrl" target="_blank">{{ $t('termsOfUse.links.termsOfUseMessagesTermsOfUseLinkLabel') }}</a>
            </template>
            <template #dataPrivacyLink>
              <a :href="dataPrivacyUrl" target="_blank">{{ $t('termsOfUse.links.termsOfUseMessagesDataPrivacyLinkLabel') }}</a>
            </template>
          </i18n>
          <i18n path="termsOfUse.termsOfUseMessages[1]" tag="p">
          </i18n>
          <i18n path="termsOfUse.termsOfUseMessages[2]" tag="p">
            <template #closeAccountLink>
              <a :href="closeAccountUrl">{{ $t('termsOfUse.links.closeAccountLinkLabel') }}</a>
            </template>
          </i18n>

          <div class="row">
            <button type="button" class="Button" @click="acceptTermsOfUse">
              {{ $t('termsOfUse.acceptTermsOfUse') }}
            </button>
          </div>

          <div class="row">
             <button type="button" class="Button linkButton" @click="rejectTermsOfUse">
              {{ $t('termsOfUse.rejectTermsOfUse') }}
          </button>

          </div>
          </div>
      </div>
    </sweet-modal>

    <sweet-modal
      ref="upgradeModal"
      icon="info"
      overlay-theme="dark"
    >
      <div class="upgradeModalContent">

        <p >
          {{ $t('pages.dashboard.upgradeModal.message') }}
        </p>

        <button type="button" class="Button" @click="goToUpgradeForm">
          {{ $t('pages.dashboard.upgradeModal.buttonLabel') }}
        </button>

        <button
          type="button"
          class="Button linkButton"
          @click="closeUpgradeModal"
        >
            {{ $t('pages.dashboard.upgradeModal.cancelButtonLabel') }}
        </button>
      </div>
    </sweet-modal>

    <sweet-modal
      ref="verificationModal"
      icon="info"
      overlay-theme="dark"
    >
      <div class="upgradeModalContent">

        <p >
          {{ $t('pages.dashboard.verificationModal.message') }}
        </p>

        <button type="button" class="Button" @click="closeVerificationModal">
          {{ $t('pages.dashboard.verificationModal.buttonLabel') }}
        </button>
      </div>
    </sweet-modal>

    <div class="container" v-if="userLoaded">
      <h1 class="Headline is-size-3" v-html="translateWithMymedelString('pages.dashboard.title')" />

      <div class="content sub-headline">
        <p class="d-block" v-t="'pages.dashboard.subtitle'" />

        <div v-if="!isBasic && !isVerified && !isRejected">
          <p v-html="translateWithMymedelString('registration.banner.unverified.text')" />
        </div>

        <div v-if="isRejected">
          <p v-html="translateWithMymedelString('registration.banner.rejected.text')" />
        </div>
      </div>

      <ul>
        <template v-if="userServicesWithoutPortal.length > 0">
          <li
            v-for="(service, index) in userServicesWithoutPortal"
            :key="'s-' + index"
          >
            <service-item :service="service" />
          </li>
        </template>
        <template v-if="inactiveServices.length > 0">
          <li
            v-for="(service, index) in inactiveServices"
            :key="'is-' + index"
            class="inactive"
            @click="onInactiveServiceItemClick"
          >
            <service-item :disableLink="true" :service="service" @click="onInactiveServiceItemClick" />
          </li>
        </template>
      </ul>
      <banner-feedback v-if="showFeedbackBanner" class="sticky"/>
      <banner-upgrade v-if="userCanUpgrade" class="mt-10"/>
      <banner-user-type v-if="isBasic && !userType" class="mt-10"/>
      <email-verification-dialog ref="verifyEmailModal" />
      <email-verified-message ref="emailVerifiedMessage" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceItem from '@/components/ServiceItem.vue'
import BannerUpgrade from '@/components/BannerUpgrade.vue'
import BannerUserType from '@/components/BannerUserType.vue'
import BannerFeedback from '@/components/BannerFeedback.vue'
import { appSettings } from '@/settings'
import EmailVerificationDialog from '@/components/EmailVerificationDialog.vue'
import EmailVerifiedMessage from '@/components/EmailVerifiedMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import i18n from '@/translations'
import { logout } from '../auth/auth0'
import * as types from '../store/mutationtypes'

export default {
  name: 'PortalDashboard',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.dashboard.meta.title')
    }
  },

  components: {
    EmailVerificationDialog,
    EmailVerifiedMessage,
    ServiceItem,
    BannerUpgrade,
    BannerUserType,
    BannerFeedback,
    i18n
  },

  mixins: [ localeMessages ],

  data () {
    return {
      language: ''
    }
  },

  computed: {
    ...mapGetters('user', [
      'userLoaded',
      'isBasic',
      'isRejected',
      'userType',
      'isProfessional',
      'isRecipient',
      'isCareGiver',
      'isVerified',
      'userServices',
      'inactiveServices',
      'user'
    ]),

    userCanUpgrade () {
      if (!(this.isBasic || this.isRejected)) {
        return false
      }
      if (!this.userType) {
        return false
      }
      if (this.isProfessional && this.countryHasProfessionalRegistration) {
        return true
      }
      return (this.isRecipient || this.isCareGiver) && this.countryHasFullRegistration
    },

    showFeedbackBanner () {
      return localStorage.getItem('showFeedbackBanner') !== 'false' && appSettings.feedback?.enabledCountries.includes(this.user.country)
    },

    countryHasProfessionalRegistration () {
      return appSettings.registrationCountriesProfessionals.includes(this.user.country)
    },

    countryHasFullRegistration () {
      return appSettings.fullRegistrationCountries.includes(this.user.country)
    },

    closeAccountUrl () {
      return appSettings.links.closeAccount
    },

    dataPrivacyUrl () {
      return appSettings.links?.dataPrivacy
    },

    termsOfUseUrl () {
      return appSettings.links.termsOfUse
    },

    userServicesWithoutPortal () {
      if (this.userServices) {
        var arrayUniqueByKey = [...new Map(this.userServices.map(item => [item['id'].toLowerCase(), item])).values()]
        return arrayUniqueByKey.filter(service => service.id !== 'portal')
      } else {
        return []
      }
    }
  },

  async mounted () {
    // try to load user profile
    if (!this.userLoaded) {
      try {
        await this.$store.dispatch('user/getUserFromDB')
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.$router.push({ name: 'reg-complete' })
          return
        } else {
          this.$store.commit('user/setError', true)
        }
      }
    }
    // only do initial check for therms of use.
    await this.$store.dispatch(types.getTermsofUse)
    this.checkTermsOfUse()

    if (this.$refs.emailVerifiedMessage.shouldShow()) {
      this.$refs.emailVerifiedMessage.open()
    }
    if (this.$refs.verifyEmailModal.isEmailVerificationRequired) {
      this.$refs.verifyEmailModal.open()
    }

    // try to load services if they have not been loaded
    if (Array.isArray(this.userServices) && this.userServices.length === 0) {
      // reset url, so services are loaded
      this.$store.commit('user/setLastLoadedServiceUrl', '')
      this.$store.commit('user/setLastLoadedInactiveServiceUrl', '')

      await this.$store.dispatch('user/getServiceClaims')
      await this.$store.dispatch('user/getInactiveServicesFromDB')
    }
    await this.$store.dispatch('user/getServiceSettings', this.$i18n.locale)
  },

  methods: {
    getUniqueObjects: function (array) {

    },
    closeVerificationModal: function (evt) {
      evt.preventDefault()
      this.$refs.verificationModal.close()
    },
    goToUpgradeForm: function (evt) {
      evt.preventDefault()
      this.$refs.upgradeModal.close()
      this.$router.push({ name: 'profile-edit', query: this.$route.query })
    },

    closeUpgradeModal: function (evt) {
      evt.preventDefault()
      this.$refs.upgradeModal.close()
    },

    onInactiveServiceItemClick: function (evt) {
      evt.preventDefault()
      if (!this.isRejected && !this.isBasic && !this.isVerified) {
        this.$refs.verificationModal.open()
      } else {
        this.$refs.upgradeModal.open()
      }
    },

    closeEmailVerification () {
      logout()
    },

    checkTermsOfUse () {
      const needsToAcceptTermsOfUse = this.$store.getters['user/isTermsOfUseConsentRequired']
      if (needsToAcceptTermsOfUse) {
        this.$refs.termsOfUseModal.open()
      }
    },

    rejectTermsOfUse () {
      logout()
    },

    async acceptTermsOfUse () {
      try {
        await this.$store.dispatch('user/acceptTermsOfUse')
        this.$refs.termsOfUseModal.close()
      } catch (err) {
        // eslint-disable-next-line no-console
          console.error('could not accept terms of use', err)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.sticky {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 500px;
    z-index: 100;
}

@media only screen and (max-width: 500px){
  .sticky {
    max-width: 100%;
    bottom: 0px;
    right: 0px;
  }
}

.inactive{
  opacity: .4;
}

.linkButton, .linkButton:hover{
  background:none;
  color:#979594;
}

.row {
  margin-top: 10px;
}

.Dashboard {
  padding-top: 180px;
  background-image: url('~@/assets/images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 80px;
  min-height: calc(100vh - 153px);
  overflow: auto;

  @include media('>=xs') {
    min-height: calc(100vh - 83px);
  }

  @include media('>=sm') {
    min-height: calc(100vh - 61px);
  }

  .content {
    color: $color-grey-600;
    line-height: 1.5;
    margin-top: 0px;

    &.sub-headline {
      margin: 10px 0 60px;

      @include media('>=md') {
        margin-top: 20px;
      }
    }
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    @include media('>=sm') {
      margin-right: -20px;
    }

    li {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      width: 100%;

      @include media('>=sm') {
        width: calc(50% - 20px);
        margin-right: 20px;
      }

      @include media('>=lg') {
        width: calc(33.33% - 20px);
        margin-right: 20px;
      }
    }
  }
}
</style>
