<template>
  <article class="Service">
    <section class="header">
      <h2 class="Headline">{{ $props.service.title }}</h2>

      <i class="icon" :class="$props.service.icon" />

      <p class="content">
        {{ $props.service.description }}
      </p>
    </section>

    <section class="footer">
      <button class="Button is-simple" open-blank @click="onServiceLinkClick($props.service.url)">
      {{ $props.service.buttonLabel }}
      </button>
    </section>
    <sweet-modal
      ref="confirmLeave"
      overlay-theme="dark"
      v-if="leavingConfirmationRequired"
      @close="unregisterNavGuard"
      @open="registerNavGuard"
    >
      <div>
        <h1 class="is-size-4" v-t="'disclaimerOnLeave.title'" />
        <div>{{$t('disclaimerOnLeave.body')}}</div>

        <div class="content sub-headline">
          <div class="row">
            <link-button
              :url="$props.service.url"
              :label="$props.service.buttonLabel"
              custom-css="is-simple"
              open-blank
              @click="closeModalWindow"
            />
          </div>
          <div class="row">
            <button type="button" class="Button linkButton" @click="closeModalWindow">
              {{ $t('disclaimerOnLeave.closeButtonLabel') }}
            </button>
          </div>
        </div>
      </div>
    </sweet-modal>
  </article>
</template>

<script>
import LinkButton from './partials/LinkButton.vue'
import { appSettings } from '@/settings'

export default {
  name: 'Service',

  components: {
    LinkButton
  },

  props: {
    service: {
      type: Object,
      required: true
    },

    basicService: {
      type: Boolean,
      default: false
    },

    disableLink: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      unregisterRouteGuard: null
    }
  },

  computed: {
    leavingConfirmationRequired () {
      return this.$props.service &&
        appSettings.disclaimerActiveServices &&
        appSettings.disclaimerActiveServices.includes(this.$props.service.id)
    }
  },

  methods: {
    // The following method is only registered as a navigation guard, when the sweet modal is open. This way, we make sure the
    // modal is closed when we change the route, and css styles are set back to normal (we had a bug, with sweet modal css setting
    // overflow:hidden on the body when clicking back in the browser, this stayed...
    // also see: https://jessarcher.com/blog/closing-modals-with-the-back-button-in-a-vue-spa/)
    back () {
      if (this.$refs.confirmLeave && this.$refs.confirmLeave.is_open === true) {
        this.closeModalWindow()
      }
    },

    onServiceLinkClick (to) {
      if (this.disableLink) {
        return
      }
      if (this.leavingConfirmationRequired) {
        this.$refs.confirmLeave.open()
      } else {
        window.open(to, '_blank')
      }
    },

    closeModalWindow () {
      this.$refs.confirmLeave.close()
    },

    registerNavGuard () {
      this.unregisterRouteGuard = this.$router.beforeEach(this.back)
    },

    unregisterNavGuard () {
      if (this.unregisterRouteGuard) {
        this.unregisterRouteGuard()
        this.unregisterRouteGuard = null
      }
    }
  }
}
</script>

<style lang="scss">
@import '../styles/_component-imports';

.Service {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 30px;
  height: 100%;

  .Headline {
    display: flex;
    align-items: center;
    min-height: 80px;
    font-weight: initial;
    padding-right: 90px;
    margin: 0 0 20px;
    line-height: 1.1;
  }

  .icon::before {
    position: absolute;
    top: 30px;
    right: 30px;
    margin-left: 30px;

     /* this needs to align with other styles made in eg serviceitem on the icomoon class*/
    /*
    same calculation as for other media query... this should be 60x60px
    */
    font-size: 40px;
    border: 2px solid black;
    padding: 8px;

    @include media('>=sm') {
      /* the icomoon icons are in total 80px here, so
      we need to take our fontsize, add the padding (on both sides) as well as 2 times the border -> this should be then if icomoon is 80px also give 80px.
      e.g.: if border is 4 px, padding is 8px then we have: 80 - 2*4 [border left/right] - 2*8 [padding also left/right]
      */
      font-size: 54px;
      border: 3px solid black;
      padding: 8px;
    }
  }

  .content {
    min-height: 100px;
  }
}
</style>
